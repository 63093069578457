<template>
  <!-- Interfaz para caja registradora -->
  <div>
    <b-card
      v-if="showTransactionsTable"
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <div v-if="showCloseCash">
              <b-button
                variant="danger"
                @click="confirmCloseCashRegister"
              >
                Cerrar caja
              </b-button>
            </div>
            <div v-else>
              <span class="text-danger">Caja cerrada hoy {{ cashData.closed_at }}</span>
            </div>
            <div v-if="showCloseCash">
              <b-button
                class="ml-1"
                variant="primary"
                @click="openModal('modal-new_transaction')"
              >
                Nuevo movimiento
              </b-button>
            </div>
          </b-col>

          <!-- Valores de la caja -->
          <b-col
            cols="12"
            md="6"
          >
            <div
              style="display: flex; align-items: center; justify-content: flex-end;"
            >
              <!-- Monto inicial -->
              <b-media
                no-body
                class="mr-2"
              >
                <b-media-aside>
                  <b-avatar
                    size="40"
                    variant="light-success"
                  >
                    <feather-icon
                      size="18"
                      icon="UnlockIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Q{{ Number(cashData.initial_amount).toFixed(2) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Monto inicial
                  </b-card-text>
                </b-media-body>
              </b-media>

              <!-- Monto final -->
              <b-media
                no-body
                class="mr-2"
              >
                <b-media-aside>
                  <b-avatar
                    size="40"
                    variant="light-danger"
                  >
                    <feather-icon
                      size="18"
                      icon="LockIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Q{{ (cashData.final_amount) ? Number(cashData.final_amount).toFixed(2) : '0.00' }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Monto final
                  </b-card-text>
                </b-media-body>
              </b-media>

            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        hover
        responsive
        :busy="tableIsBusy"
        primary-key="id"
        show-empty
        empty-text="No se han realizado movimientos en efectivo"
        :items="cashTransactions"
        :fields="cashFields"
        :per-page="perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head(id)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(created_at)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(transaction_type)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(type)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(amount)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold text-warning"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <template #cell(created_at)="data">
          {{ (data.item.created_at).split(' ')[1] }}
        </template>

        <template #cell(reason)="data">
          {{ (data.item.reason) ? data.item.reason : '-' }}
        </template>

        <template #cell(transaction_type)="data">
          <b-link
            class="font-weight-bold text-primary"
          >
            {{ data.item.transaction_type }}
            {{ (data.item.transaction) ? '#' + data.item.transaction : '' }}
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(type)="data">
          <b-badge :variant="`light-${ data.item.type === 'Entrada' ? 'success' : 'danger' }`">
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(amount)="data">
          <strong>Q.{{ data.item.amount }}</strong>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination info -->
          <b-col
            v-if="cashMeta"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ cashMeta.from }} al {{ cashMeta.to }} de {{ cashMeta.total }} registros</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="cashMeta"
              v-model="currentPage"
              :total-rows="cashMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- modal inicializar caja -->
    <b-modal
      ref="modal-open_cash"
      :title="`Abrir caja - ${ cashData.currentDate }`"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @shown="focusMountInput"
    >
      <!-- formulario para inicialización de caja -->
      <b-form
        v-if="showFormOpen"
        @submit.prevent="openCashRegister()"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Monto inicial"
              label-for="initial-amount"
            >
              <b-form-input
                id="initial-amount"
                ref="focusAmount"
                v-model="cashData.initial_amount"
                type="number"
                placeholder="Q0.00"
                step="0.01"
                min="0"
                max="999999999999.99"
                required
              />
            </b-form-group>
            <b-form-group>
              <b-button
                variant="success"
                type="submit"
              >
                Confirmar
              </b-button>
              <b-button
                class="ml-1"
                variant="outline-primary"
                @click="$router.push({ name: 'home' })"
              >
                Cancelar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <!-- modal para cerrar caja -->
    <b-modal
      ref="modal-close_cash"
      :title="`Cerrar caja - ${cashData.currentDate}`"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @shown="focusMountInput"
    >
      <!-- formulario para cierre de caja -->
      <b-form
        v-if="showFormClose"
        @submit.prevent="closeCashRegister()"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <!-- danger -->
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body">
                <span>Verifique que el efectivo en caja cuadre con el monto final</span>
              </div>
            </b-alert>

            <b-form-group
              label="Monto final"
              label-for="final-amount"
            >
              <b-form-input
                id="final-amount"
                ref="focusAmount"
                v-model="cashData.final_amount"
                type="number"
                placeholder="Q0.00"
                step="0.01"
                min="0"
                max="999999999999.99"
                required
                readonly
                disabled
              />
            </b-form-group>
            <b-form-group>
              <b-button
                variant="warning"
                type="submit"
              >
                Cerrar caja
              </b-button>
              <b-button
                class="ml-1"
                variant="outline-primary"
                @click="closeModal('modal-close_cash')"
              >
                Cancelar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

    </b-modal>

    <!-- modal para nuevo movimiento -->
    <b-modal
      ref="modal-new_transaction"
      title="Crear nuevo movimiento"
      hide-footer
      @shown="focusMountInput"
    >
      <b-form
        @submit.prevent="addNewTransaction()"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Monto"
              label-for="initial-amount"
            >
              <b-form-input
                id="initial-amount"
                ref="focusAmount"
                v-model="cashData.newTransaction.amount"
                type="number"
                placeholder="Q0.00"
                step="0.01"
                min="0"
                max="999999999999.99"
                required
              />
            </b-form-group>
            <b-form-group
              label="Razón del movimiento"
              label-for="transaction-reason"
            >
              <b-form-input
                id="transaction-reason"
                v-model="cashData.newTransaction.reason"
                type="text"
                placeholder="Razón del movimiento"
                required
              />
            </b-form-group>
            <b-form-group
              label="Tipo de movimiento"
              label-for="transaction-type"
            >
              <b-form-select
                id="transaction-type"
                v-model="cashData.newTransaction.type"
                required
              >
                <option
                  value="ingreso"
                >
                  Ingreso
                </option>
                <option
                  value="salida"
                >
                  Salida
                </option>
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <b-button
                variant="success"
                type="submit"
              >
                Confirmar
              </b-button>
              <b-button
                class="ml-1"
                variant="outline-primary"
                @click="closeModal('modal-new_transaction')"
              >
                Cancelar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      cashData: {
        id: null,
        currentDate: this.getCurrentDate(),
        initial_amount: '',
        final_amount: '',
        status: '',
        closed_at: '',

        newTransaction: {
          amount: '',
          reason: '',
          type: '',
        },
      },

      // filters
      sortField: 'cash_register_id',
      sortDesc: 'asc',
      // pagination
      perPage: 25,
      perPageOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,
      // fields
      cashFields: [
        { key: 'id', label: '#' },
        { key: 'created_at', label: 'Hora' },
        { key: 'amount', label: 'Monto' },
        { key: 'type', label: 'Tipo' },
        { key: 'transaction_type', label: 'Movimiento' },
        { key: 'reason', label: 'Razón' },
      ],

      // meta data
      cashTransactions: [],
      cashMeta: null,

      showFormOpen: true,
      showFormClose: true,
      showTransactionsTable: false,
      showCloseCash: false,
      tableIsBusy: true,
    }
  },

  watch: {
    perPage() {
      this.tableIsBusy = true
      this.getCashRegisterTransactions()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getCashRegisterTransactions()
    },
  },

  async mounted() {
    await this.getCashRegister()

    // Si existe un id de caja abierta, se muestra la tabla de movimientos
    if (this.cashData.id && this.cashData.status === 'open') {
      this.showTransactionsTable = true
      this.showCloseCash = true
      await this.getCashRegisterTransactions()

      // si existe un id de caja cerrada, se muestran transacciones del día
    } else if (this.cashData.id && this.cashData.status === 'closed') {
      this.showTransactionsTable = true
      this.showCloseCash = false
      await this.getCashRegisterTransactions()

      // Si no existe una caja abierta, se muestra el formulario para abrir una
    } else {
      this.showFormOpen = true
      this.showFormClose = false
      this.showcloseCash = true
      this.$refs['modal-open_cash'].show()
    }
  },

  methods: {
    // Consultar si existen cajas abiertas
    async getCashRegister() {
      const store = JSON.parse(localStorage.getItem('FerreAuthUser')).store_id

      const filterArray = [
        { field: 'store_id', value: store },
        { field: 'created_at', value: this.getCurrentDate() },
      ]

      await axios
        .get(`cash-register?filters=${JSON.stringify(filterArray)}`)
        .then(response => {
          if (response.data.data.length > 0) {
            this.cashData.id = response.data.data[0].id
            this.cashData.status = response.data.data[0].status
            this.cashData.closed_at = response.data.data[0].closed_at
            this.cashData.initial_amount = response.data.data[0].initial_amount
            this.cashData.final_amount = response.data.data[0].final_amount
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    // Obtener movimientos de caja registradora
    getCashRegisterTransactions() {
      if (this.perPage === 'Todos') {
        this.perPage = this.cashMeta.total
      }

      const filterArray = [
        { field: 'cash_register_id', value: this.cashData.id || '' },
      ]

      axios
        .get(`cash-register/${this.cashData.id}/transactions?filters=${JSON.stringify(filterArray)}&perPage=${this.perPage}&page=${this.currentPage}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.tableIsBusy = false
          if (response.data.data.length > 0) {
            this.cashTransactions = response.data.data
            this.cashMeta = response.data.meta
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    openModal(modal) {
      this.$refs[modal].show()
    },

    closeModal(modal) {
      this.$refs[modal].hide()
    },

    addNewTransaction() {
      axios
        .post(`cash-register/${this.cashData.id}/transactions`, this.cashData.newTransaction)
        .then(response => {
          this.makeToast('success', 'Movimiento creado', `Se ha creado el movimiento #${response.data.data.id}.`)
          this.getCashRegister()
          this.getCashRegisterTransactions()
          this.closeModal('modal-new_transaction')
          this.clearForm()
        })

        .catch(error => {
          this.showErrors(error)
        })
    },

    clearForm() {
      this.cashData.newTransaction.amount = ''
      this.cashData.newTransaction.reason = ''
      this.cashData.newTransaction.type = ''
    },

    openCashRegister() {
      axios
        .post('cash-register', this.cashData)
        .then(async response => {
          if (response.data.res) {
            this.makeToast('success', 'Nuevo ingreso', `Se ha creado la caja, transacción: #${response.data.data.id}.`)
            this.showTransactionsTable = true
            this.$refs['modal-open_cash'].hide()
            this.showCloseCash = true
            await this.getCashRegister()
            await this.getCashRegisterTransactions()
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    confirmCloseCashRegister() {
      this.$bvModal
        .msgBoxConfirm('Esta a punto de realizar el cierre del día en esta sucursal.', {
          title: '¿Desea cerrar la caja?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.showFormClose = true
            this.$refs['modal-close_cash'].show()
          }
        })
    },

    closeCashRegister() {
      axios
        .put(`cash-register/close/${this.cashData.id}`, this.cashData)
        .then(response => {
          if (response.data.res) {
            this.makeToast('success', 'Caja cerrada', `Se hizo el cierre de caja, transacción: #${response.data.data.id}.`)
            this.$refs['modal-close_cash'].hide()
            this.showCloseCash = false
            this.getCashRegister()
            this.getCashRegisterTransactions()
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    // Focus al abrir modal
    focusMountInput() {
      this.$refs.focusAmount.focus()
    },

    sortColumn(field) {
      if (this.sortField === field) {
        if (this.sortDesc === 'asc') {
          this.sortDesc = 'desc'
        } else {
          this.sortDesc = 'asc'
        }
      } else {
        this.sortField = field
        this.sortDesc = 'asc'
      }
      this.getCashRegisterTransactions()
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
